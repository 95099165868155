import {BasicsClass} from "@/common/BasicsClass";

export default class SingleStoreCardDeductionAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['list', {
            url: '/userAccountOperate/index',
            name: 'list',
            label: '单店卡扣款列表'
        }],
        ['shopBalance', {
            url: '/userAccountOperate/shopBalance',
            name: 'balance',
            label: '单店卡余额'
        }],
        ['add', {
            url: '/userAccountOperate/add',
            name: 'add',
            label: '新增单店卡扣款'
        }],
    ])

    public list(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('list').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    // 单店卡余额
    public balance(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('shopBalance').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    // 新增单店卡扣款
    public add(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('add').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
